import Fuse from 'fuse.js'
import { graphql, useStaticQuery, Link } from 'gatsby'
import moment from 'moment'
import React, { useState } from 'react'

import GridLogo from '../../components/GridLogo/GridLogo'
import SEO from '../../components/SEO/SEO'
import './SearchPage.scss'
import { ellipsis } from '../../components/Helpers/helpers'
import Exit from '../../assets/icons/Exit'
import AlisiLogo from '../../assets/images/alisi_logo.png'

const searchOptions = {
  shouldSort: true,
  includeMatches: true,
  findAllMatches: true,
  threshold: 0.3,
  distance: 100,
  useExtendedSearch: true,
  ignoreLocation: true,
  isCaseSensitive: false,
  keys: ['title', 'date', 'url']
}

const SearchPage = (): JSX.Element => {
  const [results, setResults] = useState<any>([])
  const [icon, setIcon] = useState<string>('la-search')
  const [query, setQuery] = useState<string>('')

  const searchQuery = useStaticQuery(graphql`
    query allSites {
      allSitePage(filter: { context: { globalSearch: { eq: true } } }) {
        edges {
          node {
            path
            context {
              globalSearchDetail {
                date
                title
              }
            }
          }
        }
      }
    }
  `)

  const SearchData: any = []

  searchQuery.allSitePage.edges.forEach((item: any) => {
    const resultItem = {
      url: item.node.path,
      title: item.node.context.globalSearchDetail.title,
      date: item.node.context.globalSearchDetail.date ? moment(item.node.context.globalSearchDetail.date).format('MMMM D, YYYY') : '',
      image: item.node.context.globalSearchDetail.image
    }

    SearchData.push(resultItem)
  })

  const resetQuery = (): void => {
    setQuery('')
    setIcon('la-search')
    setResults([])

    const input: any = document.getElementById('alisi-search')
    input.value = ''
  }

  const fuse = new Fuse(SearchData, searchOptions)

  const runSearch = (q: string): void => {
    if (q.trim() !== '') {
      setIcon('la-spinner')
      let searchResults = fuse.search(q).map((x: any) => x.item)

      setResults(searchResults)
      setIcon('la-search')
      setQuery(q)
    }
  }

  const NoResults = (): JSX.Element => {
    return (
      <div className="alisi-no-results">
        <div className="no-results">
          <img src={AlisiLogo} alt="alisi logo" className="fade-in-bottom dl-2 no-result-image" />
          <p className="fade-in-bottom dl-4">No results for "{query}"</p>
        </div>
      </div>
    )
  }

  const SplashScreen = (): JSX.Element => {
    return (
      <div className="alisi-no-results">
        <div className="no-results">
          <img src={AlisiLogo} alt="alisi logo" className="fade-in-bottom dl-2 no-result-image active" />
          <p className="fade-in-bottom dl-4 active">Search the website.</p>
        </div>
      </div>
    )
  }

  const handleClick = (): void => {
    window.history.back()
  }

  return (
    <>
      <SEO title="Search - ALISI | Ayala Land" />
      <div className="search-header">
        <div className="grid-bg">
          <GridLogo />
        </div>
        <div className="search-container">
          <button type="button" onClick={handleClick}>
            <Exit />
          </button>
          <div className="input-wrapper">
            <input
              placeholder="Looking for something?"
              id="alisi-search"
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  runSearch(e.target.value)
                }

                if (e.keyCode === 27) {
                  resetQuery()
                }
              }}
            />
            <div className="search-icon">
              <i className={`la ${icon} la-2x`} />
            </div>
          </div>
          <p>
            Type a keyword above and press enter to search. <span>Press Esc to reset your input.</span>
          </p>
        </div>
      </div>

      {results.length > 0 && (
        <>
          <div className="search-results">
            <p>
              {results.length} result{results.length > 1 ? 's' : ''} for "{query}"
            </p>
            {results.map((result: any, index: any) => {
              let cardImage = <img src={AlisiLogo} className="card-image" alt="alisi logo" />

              if (result.image) {
                cardImage = <img src={result.image} className="card-image" alt={result.title} />
              }

              return (
                <Link to={result.url} key={index}>
                  <div className="result-card fade-in">
                    {cardImage}
                    <div className="search-details">
                      <span>{ellipsis(result.title, 50)}</span>
                      <small>{result.date}</small>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </>
      )}

      {results.length === 0 && query.trim() === '' && <SplashScreen />}

      {results.length === 0 && query.trim() != '' && <NoResults />}
    </>
  )
}

export default SearchPage
